<template>
  <div>

    <SalesAgentEditorModal 
      :item="selected_item"
      ref="sales-agent-modal"
      @updated="updated_agent"
      @created="created_agent"
    />
    
    <div class="flex-container">
      <div class="flex-wide">
        <b-form-input
          id="filter-input"
          v-model="filters.text"
          type="search"
          
          :placeholder="$t('COMMON.SEARCH')"
        ></b-form-input>

      </div>
      <div class="flex-end">  
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2"
          @click.prevent="create_agent_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SALES.AGENT.CREATE') }}</a
        >
      </div>
      
    </div>
    
    <b-row>
      <b-col cols="4" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ items.length }}
        </div>

      </b-col>
      <b-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px; margin-bottom: 6px; height: 32px; padding: 6px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="sales-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(status)="{ item }">
        <span class="status-badge rounded" :class="item.status.toLowerCase()">{{ $t(`SALES.SALES_STATUSES.${item.status}`) }}</span>
      </template>

      <template #cell(actions)="{ item }">
        <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="select_item_clicked(item)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Write.svg" />
          </span>
        </a>

        <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_item_clicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Trash.svg" />
          </span>
        </a>
      </template>
    </b-table>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import Confirm from '@/view/components/Confirm.vue';
import axios from 'axios';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import SalesAgentEditorModal from './SalesAgentEditorModal.vue';


export default {
  name: 'SalesAgentTable',
  components: {
    Multiselect,
    Confirm,
    SalesAgentEditorModal
  },
  mixins: [ toasts ],
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    
    agent_type_options() {

      const base = [
        { text: this.$t('SALES.AGENT.TYPES.AI'), value: 'AI' },
        { text: this.$t('SALES.AGENT.TYPES.HUMAN'), value: 'HUMAN' },
      ];

      return base;
    },
  },
  props: [],
  emits: ['on_select_item', 'on_delete_item'],
  mounted() {
    this.load_agents_for_company(this.currentCompanyId, '');
    this.load_users_for_company(this.currentCompanyId);
  },
  data() {
    return {
      selected_item: {},
      items: [],
      filters: {},
      company_type_option: null,
      headers: [
        {
          label: this.$t('SALES.AGENT.NAME'),
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('SALES.AGENT.EMAIL'),
          sortable: true,
          key: 'email',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('SALES.AGENT.TYPE'),
          sortable: true,
          key: 'type',
          tdClass: 'td-short5 td-overflow',
          thClass: 'td-short5 td-overflow',
          formatter: (_, __, item) => {
            return this.agent_type_options.find(i => i.value === item.type)?.text || '';
          },
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        }
      ],
      total_rows: 0,
      current_page: 1,
      per_page: 100,
      search: null,
      user_options: []
    };
  },
  watch: {
    currentCompanyId() {
      this.load_agents_for_company(this.currentCompanyId, this.filters.text);
    },
    items() {
      
    },
    
    filters: {
      deep: true,
      handler(val) {
        this.load_agents_for_company(this.currentCompanyId, this.filters.text);
      },
    },
  
    
  },
  methods: {

    select_item_clicked(item) {
      this.selected_item = item;
      this.$refs['sales-agent-modal'].show();
    },

    async delete_item_clicked(id) {
      const res = await axios.delete(`/sales/agent/${id}`);
      if (res.status === 204) {
        this.items = this.items.filter(item => item.id !== id);
      }
    },

    updated_agent(item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.AGENT.UPDATED'));
      const index = this.items.find(i => i.id === item.id);

      if (index >= 0) {
        this.items[index] = item;
        this.items = [ ...this.items ];
      }
    },
    created_agent(item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.AGENT.CREATED'));
      this.items.unshift(item);
    },

    async create_agent_clicked() {
      this.selected_item = {};
      this.$refs['sales-agent-modal'].show();
    },
    
    async load_agents_for_company(company_id, text) {
      this.items = [];

      const str = text && text !== 'undefined' ? text : '';

      const res = await axios.get(`/sales/agents/company/${company_id}?text=${str}`);

      if (res.status === 200) {
        this.items = res.data;
      }
    },

    async load_users_for_company(company_id) {
      this.user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
        this.user_options.unshift({ text: this.$t('COMMON.ALL'), value: null });
      }
    },
    
  }
};
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
  

.flex-container {
  display: flex;
}

.flex-wide {
  width: 80%;
}

.flex-end {
  margin-left: 12px;
  align-self: end;
  justify-self: right;
}

</style>

