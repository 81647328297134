<template>
  <div>
    <!-- Button to add new column dynamically -->
    <button @click="addColumn">Add Column</button>
    
    <div class="kanban-board">
    <!-- Draggable for the columns -->
    <draggable
      v-model="columns"
      group="columns-group"
      class="columns-container"
      @end="onColumnReorder"
    >
      <!-- Each column -->
    
        <div
          v-for="(col, index) in columns"
          :key="col.id"
          class="kanban-column"
          :style="{ borderColor: col.color }"
        >
          <!-- Column Header (Name + Color) -->
          <div class="column-header">
            <input
              v-model="col.name"
              class="column-name"
              placeholder="Column Name"
            />
            <input
              type="color"
              v-model="col.color"
              class="column-color-picker"
            />
            <button @click="removeColumn(index)">X</button>
          </div>

          <!-- Draggable tasks within each column -->
          <draggable
            v-model="col.tasks"
            :group="{ name: 'tasks-group', pull: true, put: true }"
            @end="onTaskDrop"
            class="tasks-list"
          >
            
              <div
                v-for="task in col.tasks"
                :key="task.id"
                class="task-card"
              >
                <!-- A small avatar/profile icon -->
                <img
                  v-if="task.icon && task.icon.indexOf('.png') > 0"
                  :src="image_src(task.icon)"
                  alt="avatar"
                  class="task-avatar"
                />
                
                <div class="task-details">
                  <strong>{{ task.title }}</strong>
                  <p>Assigned to: {{ task.assignedTo }}</p>
                  <p>Status: {{ task.status }}</p>
                </div>
              </div>
            
          </draggable>

          <!-- Button to add new task to this column -->
          <button @click="addTask(col)">+ Add Task</button>
        </div>
      
    </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SalesKanbanBoard",
  components: {
    draggable,
  },
  data() {
    return {
      columns: [
        {
          id: 1,
          name: "To Do",
          color: "#FFD76E",
          tasks: [
            {
              id: 101,
              title: "Design homepage",
              assignedTo: "Jane",
              status: "Open",
              avatar: "https://via.placeholder.com/40",
              icon: 'robot1.png'
            },
            {
              id: 102,
              title: "Plan marketing",
              assignedTo: "Mark",
              status: "Open",
              avatar: "https://via.placeholder.com/40",
              icon: 'robot2.png'
            },
          ],
        },
        {
          id: 2,
          name: "In Progress",
          color: "#63BAFF",
          tasks: [
            {
              id: 201,
              title: "Fix login bug",
              assignedTo: "Peter",
              status: "WIP",
              avatar: "https://via.placeholder.com/40",
              icon: 'robot2.png'
            },
          ],
        },
        {
          id: 3,
          name: "Done",
          color: "#74FF80",
          tasks: [
            {
              id: 301,
              title: "Deploy new version",
              assignedTo: "Anna",
              status: "Completed",
              avatar: "https://via.placeholder.com/40",
              icon: 'robot2.png'
            },
          ],
        },
      ],
      nextColumnId: 4,
      nextTaskId: 1000,
    };
  },
  methods: {
    image_src(icon) {
      return require(`@/assets/images/${icon}`);
    },
    addColumn() {
      this.columns.push({
        id: this.nextColumnId++,
        name: "New Column",
        color: "#ffffff",
        tasks: []
      });
    },
    removeColumn(index) {
      this.columns.splice(index, 1);
    },
    addTask(col) {
      col.tasks.push({
        id: this.nextTaskId++,
        title: "New Task",
        assignedTo: "Unassigned",
        status: "Open",
        avatar: "https://via.placeholder.com/40",
        icon: 'robot2.png'
      });
    },
    onColumnReorder(evt) {
      // Called when columns are reordered
      console.log("Columns reordered", evt);
      // You can persist the new order to an API here
    },
    onTaskDrop(evt) {
      // Called when a task is dragged & dropped into a new column
      console.log("Task dropped", evt);
      // Potentially save changes to an API
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.kanban-board {
  display: flex;            /* let’s make the board a flex container if you want to place 
                               other items side by side; otherwise, keep it block. */
  flex-direction: column;   /* We have the <h1> and a button, then the columns below. */
  width: 100%;
  height: 100vh;            /* fill the entire browser height */
  margin: 0;
  padding: 0.5rem;
  box-sizing: border-box;
}


.columns-container {
  display: flex;            /* horizontally place columns side by side */
  flex: 1 1 auto;           /* expand to fill remaining vertical space */
  gap: 1rem;
  overflow-x: auto;         /* scroll horizontally if columns exceed viewport width */
  align-items: stretch;     /* make each column stretch vertically */
}


.kanban-column {
  flex: 0 0 300px;          /* fixed width, or flex: 1 if you want them to equally share space */
  min-height: 100%;         /* ensure it stretches to container’s height (which is flex: 1 from .columns-container) */
  background: #fafafa;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  box-sizing: border-box;
  width: 300px;
}

.column-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0.75rem;
}

.column-name {
  flex: 1;
  border: none;
  background: #f0f0f0;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
}

.column-color-picker {
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.tasks-list {
  min-height: 100px; /* space for tasks even if none exist */
}

.task-card {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.task-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.task-details {
  display: flex;
  flex-direction: column;
}

/* Simple transitions (optional) */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
