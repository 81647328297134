<template>
  <div>
    <div class="tabs mt-2">
      <ul class="tab-list">
        <li :class="{ active: activeTab === 0 }" @click="activeTab = 0">{{ $t('SALES.KANBAN_BOARD') }}</li>
        <li :class="{ active: activeTab === 1 }" @click="activeTab = 1">{{ $t('SALES.STATE_TEMPLATES') }}</li>
        <li :class="{ active: activeTab === 2 }" @click="activeTab = 2">{{ $t('SALES.AGENTS') }}</li>
        <li :class="{ active: activeTab === 3 }" @click="activeTab = 3">{{ $t('SALES.EMAILS') }}</li>
        <li :class="{ active: activeTab === 4 }" @click="activeTab = 4">{{ $t('SALES.STATES') }}</li>
      </ul>
      <div class="tab-content">
        <!-- Kanban -->
        <div v-show="activeTab === 0">
          <SalesKanbanBoard />
        </div>
        <div v-show="activeTab === 1">
          <SalesStateTemplateTable />
        </div>
        <div v-show="activeTab === 2">
          <SalesAgentTable />
        </div>
        <div v-show="activeTab === 3">
          <SalesAgentEmailsTable />
        </div>
        <div v-show="activeTab === 4">
          <SalesStateTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import SalesKanbanBoard from '@/view/pages/ml/ths/sales/SalesKanbanBoard.vue';
import SalesStateTemplateTable from '@/view/pages/ml/ths/sales/SalesStateTemplateTable.vue';
import SalesAgentTable from '@/view/pages/ml/ths/sales/SalesAgentTable.vue';
import SalesAgentEmailsTable from '@/view/pages/ml/ths/sales/SalesAgentEmailsTable.vue';
import SalesStateTable from '@/view/pages/ml/ths/sales/SalesStateTable.vue';


export default {
  name: 'SalesKanbanPage',
  components: {
    draggable,
    SalesKanbanBoard,
    SalesStateTemplateTable,
    SalesAgentTable,
    SalesAgentEmailsTable,
    SalesStateTable
  },
  data() {
    return {
      activeTab: 0,
      columns: [
        {
          id: 1,
          name: "To Do",
          color: "#FFD76E",
          tasks: [
            {
              id: 101,
              title: "Design homepage",
              assignedTo: "Jane",
              status: "Open",
              avatar: "https://via.placeholder.com/40"
            },
            {
              id: 102,
              title: "Plan marketing",
              assignedTo: "Mark",
              status: "Open",
              avatar: "https://via.placeholder.com/40"
            },
          ],
        },
        {
          id: 2,
          name: "In Progress",
          color: "#63BAFF",
          tasks: [
            {
              id: 201,
              title: "Fix login bug",
              assignedTo: "Peter",
              status: "WIP",
              avatar: "https://via.placeholder.com/40"
            },
          ],
        },
        {
          id: 3,
          name: "Done",
          color: "#74FF80",
          tasks: [
            {
              id: 301,
              title: "Deploy new version",
              assignedTo: "Anna",
              status: "Completed",
              avatar: "https://via.placeholder.com/40"
            },
          ],
        },
      ],
      nextColumnId: 4,
      nextTaskId: 1000,
    };
  },
  methods: {
    addColumn() {
      this.columns.push({
        id: this.nextColumnId++,
        name: "New Column",
        color: "#ffffff",
        tasks: []
      });
    },
    removeColumn(index) {
      this.columns.splice(index, 1);
    },
    addTask(col) {
      col.tasks.push({
        id: this.nextTaskId++,
        title: "New Task",
        assignedTo: "Unassigned",
        status: "Open",
        avatar: "https://via.placeholder.com/40"
      });
    },
    onColumnReorder(evt) {
      // Called when columns are reordered
      console.log("Columns reordered", evt);
      // You can persist the new order to an API here
    },
    onTaskDrop(evt) {
      // Called when a task is dragged & dropped into a new column
      console.log("Task dropped", evt);
      // Potentially save changes to an API
    },
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

</style>
